import React from "react"
import { Helmet } from "react-helmet"

import Cookies from "../components/Cookies"

function cookies() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | Polityka Prywatności</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <Cookies />
    </>
  )
}

export default cookies
